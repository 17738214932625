import { getCSRFToken } from '../helpers/get_csrf_token'
import {
  ModuleRegistry,
  AllCommunityModule,
  createGrid,
  themeAlpine,
  themeBalham,
  themeQuartz,
  type GridOptions,
  type GridApi,
  type Theme
} from 'ag-grid-community'
import {
  AllEnterpriseModule,
  LicenseManager,
  IntegratedChartsModule
} from 'ag-grid-enterprise'
import { AgChartsEnterpriseModule } from 'ag-charts-enterprise'
import { Controller } from '@hotwired/stimulus'
ModuleRegistry.registerModules(
  [
    AllCommunityModule,
    AllEnterpriseModule,
    IntegratedChartsModule.with(AgChartsEnterpriseModule)
  ]
)
LicenseManager.setLicenseKey('Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-076645}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Commit_Digital_Limited}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Exposure_Analytics}_only_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{Exposure_Analytics}_need_to_be_licensed___{Exposure_Analytics}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{6_February_2026}____[v3]_[0102]_MTc3MDMzNjAwMDAwMA==414a370f5e1eb1de9c4937d2d7e75b9e')

export default class extends Controller {
  static values = {
    updateReportUrl: String,
    columnDefs: String,
    rowData: String,
    initialState: String,
    theme: String
  }

  static targets = ['grid', 'saveButton', 'themeSelect']

  declare readonly gridTarget: HTMLElement
  declare readonly saveButtonTarget: HTMLButtonElement
  declare readonly themeSelectTarget: HTMLSelectElement
  declare readonly updateReportUrlValue: string
  declare readonly columnDefsValue: string
  declare readonly rowDataValue: string
  declare readonly initialStateValue: string
  declare readonly themeValue: string
  declare gridApi: GridApi
  declare currentTheme: string
  declare themes: Record<string, Theme>

  connect (): void {
    this.themes = {
      quartz: themeQuartz,
      balham: themeBalham,
      alpine: themeAlpine
    }
    this.currentTheme = this.themeValue
    this._setupThemeSelect()

    const columnDefs = JSON.parse(this.columnDefsValue)
    this._addValueGetters(columnDefs)
    this._addValueFormatters(columnDefs)

    const gridOptions: GridOptions = {
      rowData: JSON.parse(this.rowDataValue),
      columnDefs,
      initialState: JSON.parse(this.initialStateValue),
      theme: this.themes[this.currentTheme],
      defaultColDef: {
        minWidth: 100,
        flex: 1,
        floatingFilter: true
      },
      statusBar: {
        statusPanels: [
          { statusPanel: 'agTotalAndFilteredRowCountComponent' },
          { statusPanel: 'agTotalRowCountComponent' },
          { statusPanel: 'agFilteredRowCountComponent' },
          { statusPanel: 'agSelectedRowCountComponent' },
          { statusPanel: 'agAggregationComponent' }
        ]
      },
      rowGroupPanelShow: 'always',
      sideBar: true,
      enableCharts: true,
      cellSelection: true,
      onStateUpdated: () => { this._enableSaveButton() }
    }

    this.gridApi = createGrid(this.gridTarget, gridOptions)
  }

  disconnect (): void {
    this.gridApi.destroy()
  }

  setTheme (e: any): void {
    this.currentTheme = e.target.value
    this.gridApi.setGridOption('theme', this.themes[this.currentTheme])
  }

  async updateReport (): Promise<Response> {
    const body = {
      report: {
        initial_state: this.gridApi.getState(),
        theme: this.currentTheme
      }
    }

    this._disableSaveButton()

    return await fetch(this.updateReportUrlValue, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': getCSRFToken()
      },
      body: JSON.stringify(body)
    })
  }

  _addValueGetters (columnDefs: any): void {
    columnDefs.forEach((columnDef: any) => {
      if (columnDef.valueGetter === 'unixToDate') {
        columnDef.valueGetter = (params: any) => { return params.data !== undefined ? new Date(params.data[columnDef.context] * 1000) : null }
      }
    })
  }

  _addValueFormatters (columnDefs: any): void {
    columnDefs.forEach((columnDef: any) => {
      if (columnDef.valueFormatter === 'toLocaleDateString') {
        columnDef.valueFormatter = (params: any) => { return params.value !== null ? params.value.toLocaleDateString() : null }
      }
    })
  }

  _setupThemeSelect (): void {
    Array.from(this.themeSelectTarget.options).forEach((option: HTMLOptionElement) => {
      if (option.value === this.currentTheme) {
        option.selected = true
      }
    })
  }

  _enableSaveButton (): void {
    this.saveButtonTarget.disabled = false
  }

  _disableSaveButton (): void {
    this.saveButtonTarget.disabled = true
  }
}
